<template>
  <main>
    <slot />
  </main>

  <aside class="modal">
    <ModalsContainer />
  </aside>
</template>

<script>
import { ModalsContainer, useVfm } from 'vue-final-modal';

export default {
  name: 'GuideLayout',
  components: { ModalsContainer },
  props: {},

  /********************************************************************************************************/
  /* ❗ 개발 준수 사항 (필독)                                                                               */
  /* 모니모 반응형 변수 선언은 ref 형태로 선언  (reactive 선언 사용금지) - 변수 사용법 일치                    */
  /********************************************************************************************************/
  setup(props, context) {
    /***************************************************/
    /* 🌟publisher: 퍼블에서 정의 된 data, method 작성  */
    /***************************************************/
    // 📢 publisher data 정의 / pubData 변수명 변경 금지
    const pubData = {
      vfm: useVfm(),
    };

    // 📢 퍼블리셔 data allData로 병합
    let allData = { ...pubData }; // 📢 코드 수정 금지

    // 📢 publisher method 정의 / pubMethod 변수명 변경 금지 / 함수선언 후 함수내에서 반드시 allData로 변수접근
    const pubMethod = {
      //   // 예제
      //   onClick: () => {
      //   // 함수내에서 변수 접근시 반드시 allData 하위로 접근
      //   // pubData.paramA.value = '변경' -> ❌
      //   // allData.paramA.value = '변경' -> ⭕
      //   }

      // 레이아웃에 사용되는 Props 데이터 가져오는 함수 정의
      setLayoutData: () => {
        // const arrayPath = window.location.search.split('-');
        // const pageName = arrayPath.filter(value => {
        //   return value.toLowerCase().search('pgi') > -1;
        // });
        // if (isRoutePatternCorrect(arrayPath)) {
        //   const dataLayout = findDataLayout(pageName.toString());
        //   applyLayout(dataLayout);
        // } else {
        //   console.log('layout error');
        //   // Error case
        // }
      },

      onClickTextBtn: arg => {
        console.log(arg);
      },
      onClickBack: () => {
        console.log('onClickBack');
      },
      onClickAdd: () => {
        console.log('onClickAdd');
      },
      onClickClose: () => {
        console.log('onClickClose');
      },
      onClickShowInfo: () => {
        console.log('onClickShowInfo');
      },
      onClickShowMore: () => {
        console.log('onClickShowMore');
      },
      onClickAlarm: () => {
        console.log('onClickAlarm');
      },
    };

    /*********************************************************************/
    /* 🌟developer : 개발에서 정의된 data, method 작성                    */
    /* 퍼블리셔가 작성한 data 및 method 변수명 중복 시 overwrite 하여 개발  */
    /*********************************************************************/
    // 📢 developer data 정의
    allData = {
      ...allData, // 📢 삭제금지
      // 📢 추가 변수 정의 및 overwrite 영역
    };

    // 📢 developer method 정의 / allMethod 변수명 변경 금지
    const allMethod = {
      ...pubMethod, // 📢 삭제금지
      // 📢 추가 함수 정의 및 overwrite 영역  / 반드시 allData에 접근하여 변수 처리
    };

    /*********************************************************************/
    /* 🌟computed 관련 선언                                              */
    /*********************************************************************/
    // const propsLayoutData = computed(() => useUiStore().getLayout);

    /****************************************************/
    /* 🌟watch 관련 선언                                */
    /****************************************************/

    /*************************************************************/
    /* 🌟 lifecycle관련                                          */
    /*  린트 (no-lifecycle-after-await)시 해당영역 최상단으로 이동  */
    /*  해당 사용하는 라이프사이클 주석 해제하여 사용                */
    /**************************************************************/

    // created
    // pubMethod.setLayoutData();
    //
    //  onBeforeMount(() => {
    //
    //  });
    //
    //  onMounted(() => {
    //
    //  });
    //
    //  onBeforeUpdate(() => {
    //
    //  });
    //
    //  onUpdated(() => {
    //
    //  });
    //
    onBeforeUnmount(() => {
      // allData.vfm.closeAll();
    });
    //
    //  onUnmounted(() => {
    //
    //  });

    /******************************************************/
    /* 🌟기타                                             */
    /******************************************************/

    /***********************************************************************/
    /* 🌟return                                                           */
    /* 순서 변경시 비정상 동작 할 수 있음 후순위로 선언된 인자가 overwrite 됨  */
    /***********************************************************************/
    return { ...allData, ...allMethod, propsLayoutData };
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  height: calc(100dvh - $default-header-height);
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #000;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
}
</style>
